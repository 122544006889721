.checkbox-grid-options {
  display: flex;
  flex-direction: column;
  margin: 0.5em;

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5em;
  }

  .options-list {
    display: flex;
    flex-direction: column;
    margin: 0.5em 0 1em;

    &__add-button {
      margin-top: 0.5em;
    }

    &__move-controls {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon-button {
        margin: 0.24em 0;
        transform: scale(1.4);
      }
    }

    &__item {
      display: flex;
      align-items: center;
      margin: 0.3em 0;
      padding: 0;

      .input__field {
        flex-grow: 1;
        margin: 0 0.5em;
      }

      input[type="number"] {
        max-width: 80px;
        margin-left: 0.5em;
      }
    }
  }
}