.checkbox-grid-response {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__choices {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.5rem;
  }

  &__circle {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid #d3d3d3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    color: #d3d3d3;

    &--selected {
      background-color: #1e90ff;
      color: white;
      border-color: #1e90ff;
    }
  }

  &__label {
    font-size: 1rem;
    font-weight: bold;
    color: #555;
    margin-top: 0.5rem;
    text-align: center;
  }
}