@import "~styles/_animations";
@import "~styles/_colors";

.checkbox_wrapper {
  transform: translateX(0);
  cursor: pointer;
}

.checkbox {
  display: flex;
  margin-bottom: 1.5em;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &.no-margin {
    margin-bottom: 0;
  }

  &.small-margin {
    margin-bottom: 0.5em;
  }

  .checkbox__label {
    font-weight: normal;
    font-size: 0.85em;
    &::first-letter {
      text-transform: capitalize;
    }
    &:before {
      content: "";
      visibility: hidden;
      opacity: 0;
      transition: 0.25s;
    }

    &.bold {
      font-weight: bold;
    }

    &.disabled {
      opacity: 0.6;
    }
  }

  .checkbox__field {
    padding: 0.5em;
    margin: 0em;
    margin-inline-end: 1em;
    border-radius: 3px;
    border: 1px solid $border-light;
    appearance: none;
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;
    background: white;
    transition: 0.25s;

    &:hover {
      border-color: $border-dark;
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $brand-primary-accent200;
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: rgba(0, 0, 0, 0.21) 0px 4px 17px 0px;
    }

    &.error {
      border-color: $color-danger;
    }

    &:checked {
      border-color: $brand-primary-accent;
      background-color: $brand-primary-accent;

      + .checkbox__label {
        &:before {
          content: "✓";
          visibility: visible;
          opacity: 1;
          color: white;
          position: absolute;
          inset-inline-start: 0.6em;
          font-size: 0.8em;
          font-weight: bold;
          top: 0.1em;
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.1;
    }

    &_styled {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      &:hover,
      &:focus,
      &:active {
        border-color: $border-light;
        box-shadow: none;
      }
      &:checked {
        border-color: $carolina-blue;
        background-color: $carolina-blue;
        + .checkbox__label {
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            content: "";
            background: url("../../../assets/icons/check2.svg") center/16px 16px
              no-repeat;
            fill: white;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  .checkbox__error {
    color: $color-danger;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  .checkbox__hint {
    color: $text-grey;
    margin-top: 0.5em;
    margin-bottom: 0;
    margin-inline-start: 0.15em;
  }

  &.outlined {
    padding: 0.25em 0.5em;
    border: 1px solid $border-light;
    border-radius: 2px;
    margin: 0 0.5em;
    position: relative;

    .checkbox__label {
      font-weight: bold;
    }

    .checkbox__field {
      &:checked {
        + .checkbox__label {
          &:before {
            inset-inline-start: 1.2em;
            top: 0.5em;
          }
        }
      }
    }
  }

  .grey {
    font-size: 14px;
    line-height: 1.42;
    color: rgba(0, 0, 0, 0.56);
  }
}
