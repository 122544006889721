@use "~styles/animations";
@use "~styles/colors";

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
}

.input__label {
  font-weight: bold;
  margin-bottom: 0.5em;
  &::first-letter {
    text-transform: capitalize;
  }
}

.input__field {
  padding: 1em;
  border-radius: 3px;
  border: 1px solid colors.$border-light;
  &.small {
    padding: 0.5em;
  }
  &::placeholder {
    color: colors.$border-light;
    transition: animations.$baseTransition;
  }

  &:hover {
    border-color: colors.$border-dark;

    &::placeholder {
      color: colors.$border-dark;
    }
  }

  &:focus,
  &:active {
    outline: none;
    border-color: colors.$brand-primary-accent200;

    &::placeholder {
      opacity: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: colors.$boxshadow-light;
  }

  &.error {
    border-color: colors.$color-danger;
  }
}

.input__error {
  color: colors.$color-danger;
  margin-top: 0.5em;
  margin-bottom: 0;
  margin-inline-start: 0.15em;
}

.input__hint {
  color: colors.$text-grey;
  margin-top: 0.5em;
  margin-bottom: 0;
  margin-inline-start: 0.15em;
}

.input__optional-label {
  font-weight: 400;
}
