@use "~styles/colors";
@use "~styles/animations";

.data-table-row {
  transition: animations.$baseTransition;
  border-top: 1px solid colors.$border-light;
  cursor: pointer;

  &:hover {
    transition: animations.$baseTransition;
    border-top: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.05) 1px 2px 3px 3px;
  }

  .data-table-cell {
    padding: 1em 0.75em;
  }
}
