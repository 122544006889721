.checkbox-grid {
  &__label {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(20% - 1rem);
    text-align: center;

    input[type="checkbox"] {
      margin-bottom: 0.5rem;
      cursor: pointer;
      width: 1.5em;
      height: 1.5em;
    }

    label {
      font-size: 0.85rem;
    }
  }
}