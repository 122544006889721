@use "styles/index.scss";
@use "styles/spacing.scss";

.reflective-app {
  background: white;
}

// CHATBOX
// Button to open / close chatbox
.sb-chat-launcher-iframe {
  @media screen and (max-width: spacing.$viewport7) {
    bottom: 4rem !important;
  }
}

// Chatbox window
.sb-chat-widget-iframe {
  @media screen and (max-width: spacing.$viewport7) {
    height: calc(100% - 9rem) !important;
    bottom: 8rem !important;
  }
}